import { selectFile } from './utils/readFile'
let app = document.querySelector('#app')
let loading = document.querySelector('#appLoading')
let container = document.createElement('div')
let title = document.createElement('div')
let input = document.createElement('input')
let button = document.createElement('button')
let buttonSlt = document.createElement('button')

container.setAttribute('id', 'container')

title.setAttribute('id', 'title')
title.innerText = 'RdPlay 选择文件或输入项目文件网址'
let textdes = document.createElement('div')
textdes.setAttribute('id', 'textdes')
textdes.innerText = '如果运行出错，或重新选择，请刷新页面'
title.appendChild(textdes)

input.setAttribute('placeholder', '输入你的项目地址')
input.setAttribute('id', 'appInput')
button.innerText = '运行'
button.setAttribute('id', 'appButton')
buttonSlt.innerText = '选择文件运行'
buttonSlt.setAttribute('id', 'buttonSlt')



button.onclick = function () {
   if (input.value) {
      loadProject(input.value)
   }
}
buttonSlt.onclick = function () {
   selectFile((data) => {
      loadProject(data)
   })
}


container.appendChild(title)
container.appendChild(input)
container.appendChild(button)
container.appendChild(buttonSlt)

const getQueryParams = function (name) {
   const params = {}
   const queryString = window.location.search.substring(1)
   const pairs = queryString.split('&')
   for (const pair of pairs) {
      const [key, value] = pair.split('=')
      if (key) {
         params[decodeURIComponent(key)] = decodeURIComponent(value || '')
      }
   }
   return name ? params[name] : params
}

window.show = function () {
   const url = getQueryParams('url')
   if (url) {
      loadProject(encodeURI(url))
   } else {
      loading.className = 'hide'
      app.appendChild(container)
   }
}